<template>
  <v-container>
    <v-row class="p-relative zindex-1">
      <v-col cols="12" align="center" justify="center" v-if="$vuetify.breakpoint.mdAndUp">
        <h3 class="heading--text">My Progress</h3>
        <p class="muted-1 ma-0">Click the tiles to check your progress and notes taken during sessions.</p>
      </v-col>

      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-col cols="12">
          <v-expansion-panels class="meo-accordian">
            <v-expansion-panel class="meo-accordian-item" v-for="item in modules" :key="item._id">
              <v-expansion-panel-header
                :class="{ 'opacity-down': started(item) }"
                :ripple="{ class: 'primary--text' }"
                @click.native="goTo(item)"
              >
                <v-card class="meo-accordian-card" :dark="item.dark">
                  <div class="header-inner">
                    <div class="item-image mr-4">
                      <template v-if="(item.data && item.data.image) || item.image">
                        <div class="image" :style="backgroundImage(item)"></div>
                      </template>

                      <template v-if="(item.data && item.data.icon) || item.icon">
                        <template v-if="(item.data && !item.data.image) || !item.image">
                          <div class="image">
                            <i class="dark--text" :class="(item.data && item.data.icon) || item.icon"></i>
                          </div>
                        </template>

                        <template v-else>
                          <img :src="item.image" />
                        </template>
                      </template>
                    </div>

                    <div class="text mr-2 d-flex flex-column" :class="{ 'muted-1': locked(item) }">
                      <div class="line-clamp">
                        <b v-html="item.data && item.data.displayTitle ? item.data.displayTitle : item.title"></b>
                      </div>

                      <div class="line-clamp">
                        <span
                          class="caption muted-1"
                          v-if="(item.data && item.data.description) || item.description"
                          v-html="item.data && item.data.description ? item.data.description : item.description"
                        ></span>
                      </div>
                    </div>
                  </div>
                </v-card>

                <template v-slot:actions>
                  <v-icon v-if="completed(item)" class="noRotate" color="success">
                    mdi-check-circle-outline
                  </v-icon>
                  <v-icon v-if="locked(item)" class="noRotate opacity-down">
                    mdi-lock-outline
                  </v-icon>
                  <v-icon v-if="!locked(item) && !completed(item)">
                    mdi-chevron-down
                  </v-icon>
                </template>
              </v-expansion-panel-header>

              <v-expansion-panel-content class="meo-accordian-content">
                <div class="d-flex flex-row justify-space-around align-center mt-2">
                  <div class="d-flex flex-column">
                    <div
                      :class="{ 'mb-4': item.notes && item.notes.length }"
                      class="d-flex align-center mb-2"
                      v-for="completion in item.completion"
                      :key="completion._id"
                    >
                      <i class="mr-2" :class="completion.icon"></i>
                      <b>{{ completion.title }}: </b>
                    </div>
                  </div>
                  <div class="d-flex flex-column">
                    <div
                      :class="{ 'mb-4': item.notes && item.notes.length }"
                      class="d-flex align-center height30 mb-2"
                      v-for="completion in item.completion"
                      :key="completion._id"
                    >
                      <b :class="completionClass(item, completion)" v-html="completionText(item, completion)"></b>
                    </div>
                  </div>
                </div>

                <div class="notes" v-if="item.notes && item.notes.length">
                  <p class="d-flex justify-center mb-1">
                    <v-icon class="px-1" size="17" color="black">mdi-pencil</v-icon><b>Notes:</b>
                  </p>

                  <v-card class="pa-2 rounded10" color="#fffbdb" elevation="0">
                    <div v-html="item.notes"></div>
                  </v-card>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </template>

      <template v-else>
        <v-col cols="12" md="4" lg="3" v-for="(item, index) in modules" :key="index">
          <meo-card-item
            :item="item"
            :purchased="!locked(item)"
            :completed="completed(item)"
            @click.native="goTo(item)"
          ></meo-card-item>
        </v-col>

        <progress-dialog
          ref="progressDialog"
          @cancel="$refs.progessDialog.close()"
          @confirm="$refs.progessDialog.close()"
        ></progress-dialog>
      </template>
    </v-row>
  </v-container>
</template>

<script>
// Services
import Module from "@/modules/meo-module/module";
import Progress from "@/modules/meo-module/progress-document";

// Components
import MeoCardItem from "@/components/misc/meo-card-item.vue";
import ProgressDialog from "@/components/dialogs/progress-dialog.vue";

export default {
  name: "profile-progress",
  meta: {
    titleTemplate: "Progress |  %s",
  },

  props: {
    sections: {
      type: Array,
    },
  },

  data() {
    return {
      modules: [],
    };
  },
  components: {
    MeoCardItem,
    ProgressDialog,
  },
  computed: {},

  methods: {
    async init() {
      this.$meo.loading = true;

      let criteria = {
        includeUnmatched: true,
        allDefinitions: true,
        select: ["data", "items"],
        sort: {
          key: "title",
          direction: "asc",
          type: "string",
        },
        filter: {
          operator: "and",
          filters: [
            {
              key: "status",
              comparator: "in",
              values: ["active"],
            },
          ],
        },
      };

      // Filter out bundle
      let modules = await this.$fluro.content.filter("meoModule", criteria);
      modules = modules.filter((item) => item._id !== this.$meo.bundle);
      // Find free products
      let freeProducts = modules.filter((item) => item.data && item.data.freeProduct);

      // Get free product items and reattach
      let ids = [].concat.apply(
        [],
        freeProducts.map(({ items }) => items)
      );
      let freeItems = await this.$fluro.content.getMultiple("", ids, {});

      freeProducts.forEach((product) => {
        let items = product.items || [];

        freeItems.forEach((item) => {
          if (items.includes(item._id)) {
            let index = items.findIndex((indexItem) => indexItem == item._id);
            product.items.splice(index, 1, item);
          }
        });
      });

      // Get products the user has access to
      let products = await this.$fluro.api.get("/my/products/meoModuleScreen").then(({ data }) => data);
      // Add free free products
      products = products.concat(freeProducts);
      // Get progress
      let progress = await Progress.list().then(({ data }) => data);

      if (!progress.length) {
        progress = [this.$localProgress.progressDoc];
      } 

      console.log("loading progress", progress);
      // Replace any modules with the users products. This is so we can access the screen item content
      let completedModules = progress[0] && progress[0].data && progress[0].data.modules;
      // Figure out what they have completed/not completed for the products
      products.forEach((product) => {
        let item = product;
        let index = modules.findIndex((module) => module._id === item._id);

        let match = completedModules ? completedModules.find((module) => module.module._id === item._id) : {};

        item.completion = [];

        let items = (item && item.items) || [];

        if (match && match.module && match.module._id) {
          item.notes = match.notes;

          let complete = (match && match.screens) || [];
          let incomplete =
            items.filter(
              (incompleteItem) => !complete.some((completeItem) => completeItem._id == incompleteItem._id)
            ) || [];

          complete.forEach((screen) => {
            let _id = screen._id;
            let title = screen.data && screen.data.sectionName;
            let icon = (screen.data && screen.data.icon) || "";

            item.completion.push({
              _id,
              title,
              icon,
              completed: true,
            });
          });

          incomplete.forEach((screen) => {
            let _id = screen._id;
            let title = screen.data && screen.data.sectionName;
            let icon = (screen.data && screen.data.icon) || "";

            item.completion.push({
              _id,
              title,
              icon,
              completed: false,
            });
          });
        } else {
          items.forEach((screen) => {
            let _id = screen._id;
            let title = screen.data && screen.data.sectionName;
            let icon = (screen.data && screen.data.icon) || "";

            item.completion.push({
              _id,
              title,
              icon,
              completed: false,
            });
          });
        }

        let order = ["Reading", "Meditation", "Recreation"];

        item.completion = item.completion.sort((a, b) => order.indexOf(a.title) - order.indexOf(b.title));

        modules.splice(index, 1, item);
      });

      this.modules = modules.sort((a, b) => {
        let first = a.data && a.data.order;
        let second = b.data && b.data.order;

        return first - second;
      });

      this.$meo.loading = false;
    },
    backgroundImage(item) {
      if ((item.data && !item.data.icon) || !item.icon) {
        if ((item.data && item.data.image) || item.image) {
          return {
            backgroundImage: `url(${this.$fluro.asset.imageUrl(item.data.image || item.image)})`,
          };
        }
      }

      return {};
    },

    locked(item) {
      if (
        (item.completion && item.completion.length && item.completion.length >= 1) ||
        (item.data && item.data.freeProduct)
      ) {
        return false;
      }

      return true;
    },
    completionClass(currentProduct, currentScreen) {
      if (currentScreen.completed) {
        return "success--text";
      }

      let progress = currentProduct.completion.some((item) => item.completed);

      if (progress) return "warning--text";

      return "error--text";
    },
    completionText(currentProduct, currentScreen) {
      if (currentScreen.completed) {
        return "Complete";
      }

      let progress = currentProduct.completion.some((item) => item && item.completed);

      if (progress) return "In Progress";

      return "Incomplete";
    },
    completed(item) {
      if (item.completion && item.completion.length) {
        let numberCompleted = item.completion.filter((item) => item && item.completed === true);

        if (item.completion.length === numberCompleted.length) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    started(item) {
      if (item.completion && item.completion.length) {
        let numberCompleted = item.completion.filter((item) => item && item.completed === true);

        if (numberCompleted.length === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    goTo(item) {
      if (!this.locked(item) && this.$vuetify.breakpoint.mdAndUp) {
        this.$refs.progressDialog.open(item);
      } else if (this.locked(item)) {
        console.log(item._id, "this is the id");
        this.$router.push({ name: "purchase", params: item._id });
      }
    },
  },

  async created() {
    await this.init();
  },
};
</script>

<style lang="scss">
.meo-accordian {
  border-radius: 0;
  box-shadow: none;

  .meo-accordian-item {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
    border-radius: 10px !important;
    border: none !important;
    margin: 0 0 16px 0 !important;

    &:after,
    &:before {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
      border: none !important;
    }

    .meo-accordian-content {
      .v-expansion-panel-content__wrap {
        padding: 0 10px 10px;
      }
    }

    .v-expansion-panel-header {
      padding: 10px;
      box-shadow: none !important;
      border: none !important;

      &:before {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
        border: none !important;
      }
    }

    .meo-accordian-card {
      background: #ffffff;
      border-radius: 10px !important;
      position: relative;
      display: flex;
      align-items: center;
      box-shadow: none !important;
      border: none !important;

      &:after,
      &:before {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1) !important;
        border: none !important;
      }

      .header-inner {
        position: relative;
        display: flex;
        align-items: center;

        .item-image {
          width: 47px;
          height: 47px;
          background: var(--v-primary-base);
          border-radius: 10px;
          padding: 5px;
          flex-shrink: 0;
          position: relative;

          .image {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-color: #ffffff;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .line-clamp {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
    }
  }
}
.noRotate {
  transform: rotate(0deg) !important;
}

.opacity-down {
  opacity: 0.65 !important;
}
.height30 {
  height: 30px;
}
</style>
