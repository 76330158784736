<template>
<v-dialog v-model="display" content-class="confirm" width="500">
    <v-card class="alert-dialog no-selection dark--text d-flex align-center flex-column">
        <meo-card-item style="width:250px; cursor:default;" section :item="model" hide-details flat text-center></meo-card-item>
        <meo-progress-tracker class="full-width" hide-title :module="model" :items="sections" :screens="screens" flat></meo-progress-tracker>

        <div class="notes text-center" v-if="model && model.notes && model.notes.length">
            <h3>Notes</h3>
            <p>
                {{model.notes}}
            </p>
        </div>
    </v-card>
</v-dialog>
</template>

<script>
import _ from 'lodash'

import MeoCardItem from "@/components/misc/meo-card-item.vue";
import MeoProgressTracker from "@/components/misc/meo-progress-tracker.vue";

export default {
    props: {
        title: {
            type: String,
            default () {
                return 'Alert'
            }
        },
        actionLabel: {
            type: String,
            default () {
                return 'OK'
            }
        },
        cancelLabel: {
            type: String,
            default () {
                return 'Cancel'
            }
        },
        actionColor: {
            type: String,
            default () {
                return 'primary'
            }
        },
        cancelColor: {
            type: String,
            default () {
                return '#EEEEEE'
            }
        },
        hideAction: Boolean,
        hideCancel: Boolean
    },

    components: {
        MeoCardItem,
        MeoProgressTracker
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            model: null,
            message: '',
            errorMessage: ''
        }
    },
    computed: {
        isValid() {
            return !this.loading
        },
        dialogMessage() {
            if (this.showError) return this.errorMessage || 'An error occured. Try again.'
            return this.message || '';
        },
        sections() {
            return (this.model && this.model.items) || [];
        },
        screens() {
            let screens = this.model && this.model.completion || [];

            if (screens && screens.length) {
                screens = screens.filter(item => item.completed);
            }

            return screens;
        }
    },
    methods: {
        init(model) {
            this.showError = false
            this.loading = false

            if (!model) {
                this.model = null
            } else {
                let clonedModel = _.cloneDeep(model)
                this.model = clonedModel
            }
        },
        open(model, message) {
            this.init(model)
            this.message = message
            this.display = true
        },
        error(message) {
            this.errorMessage = message
            this.showError = true
            this.loading = false
        },
        confirm() {
            this.loading = true
            this.$emit('confirm', this.model)
        },
        cancel() {
            this.$emit('cancel')
            this.close()
        },
        close() {
            this.display = false
        }
    },
}
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 100px 80px rgba(74, 34, 202, 0.07), 0 22.3363px 17.869px rgba(74, 34, 202, 0.0417275), 0 6.6501px 5.32008px rgba(74, 34, 202, 0.0282725) !important;
}

.alert-dialog {
    border-radius: 10px !important;

    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }

    .p-absolute {
        position: absolute !important;
    }
    .p-relative {
        position: relative !important;
    }
}
</style>
